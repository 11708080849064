import React, { useState, useEffect } from 'react';
import { Route, Routes, Link, useNavigate, useLocation, NavLink } from 'react-router-dom';
import Home from './components/Home';
import HomeBrands from './components/HomeBrands';

import Feed from './components/Feed';
import StandalonePoll from './components/StandalonePoll';
import ReviewPoll from './components/ReviewPoll';

import EmbedPoll from './components/EmbedPoll';
import SignUpPage from './components/SignUpPage'; // Updated import
import LoginPage from './components/LoginPage'; // Updated import
import Profile from './components/Profile';
import PublicProfile from './components/PublicProfile';
import { jwtDecode } from 'jwt-decode'; // Fixed import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './App.css';
import VerifyEmail from './components/VerifyEmail';
import Poll from './components/Poll';
import MainDashboard from './components/MainDashboard';
import BrandDashboard from './components/BrandDashboard';

import ResetPassword from './components/ResetPassword';
import InitiateResetPassword from './components/InitiateResetPassword';
import LiveDashboard from './components/LiveDashboard'; // Import LiveDashboard
import ConnectedPoll from './components/ConnectedPoll'; // Import ConnectedPoll component
import NotificationsPage from './components/NotificationsPage'; // Import NotificationsPage component
import PaymentSuccess from './components/PaymentSuccess';
import CreatePoll from './components/CreatePoll';
import logger from './components/logger';
import Contact from './components/Contact';
import About from './components/About';
import Privacy from './components/Privacy';
import UserAgreement from './components/UserAgreement';
import Terms from './components/Terms';
import FAQs from './components/FAQs';
import Team from './components/Team';
import ProfileSettings from './components/ProfileSettings'; // Import ProfileSettings
import Logo from './assets/logo.png';
import AdminDashboard from './components/AdminDashboard'; // Import AdminDashboard at the top.













function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [username, setUsername] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [userEmail, setUserEmail] = useState('');
    // Determine if the current route is for login or signup
  const isHomePage = location.pathname === '/'; // Add this inside the App component
  const [subscriptionStatus, setSubscriptionStatus] = useState(''); // State for subscription status
  const isAuthRoute = location.pathname === '/signup' || location.pathname === '/login';
  const isEmbedRoute = location.pathname.startsWith('/embed/');
  const hideNavBarRoutes = ['/', '/about', '/faqs', '/contact', '/dashboard/main', '/brands'];
  const [firstName, setFirstName] = useState(''); // Add firstName state
  const [lastName, setLastName] = useState('');  // Add lastName state

  const shouldHideNavBar = isEmbedRoute || isAuthRoute || hideNavBarRoutes.includes(location.pathname);
  
  const hideTopBarRoutes = ['/dashboard/main'];

  const shouldHideTopBar = hideTopBarRoutes.includes(location.pathname);

  const [hash, setHash] = useState(''); // Add this line

  const [currentDashboard, setCurrentDashboard] = useState('creator');

  const toggleDashboard = () => {
    setCurrentDashboard((prev) => (prev === 'creator' ? 'brand' : 'creator'));
  };


  // Function to fetch the token
  const fetchToken = async () => {
    try {
      const response = await fetch('/api/generate-token');
      const { accessToken } = await response.json();
      localStorage.setItem('accessToken', accessToken); // Store the access token in localStorage

      // Decode the token to get the expiration time
      const decodedToken = jwtDecode(accessToken);
      logger.log('Access token fetched and stored:', accessToken);
      logger.log('Token expires at:', new Date(decodedToken.exp * 1000).toISOString());
    } catch (error) {
      logger.error('Error fetching access token:', error);
    }
  };


  useEffect(() => {
    // If the user is not logged in, fetch a token when the app loads
    if (!isLoggedIn) {
      fetchToken();
    }
  }, [isLoggedIn]);


  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
        if (!userId) return; // Only fetch if userId is available
  
        try {
            const response = await fetch(`/auth/subscription-status/${userId}`);
            if (response.ok) {
                const data = await response.json();
                setSubscriptionStatus(data.subscriptionStatus); // Update subscription status
                setUsername(data.username);
            } else {
                logger.error('Failed to fetch subscription status, setting as Free');
                setSubscriptionStatus('Free'); // Default to Free if fetching fails
            }
        } catch (error) {
            logger.error('Error fetching subscription status:', error);
            setSubscriptionStatus('Free'); // Default to Free on any error
        }
    };
  
    fetchSubscriptionStatus();
}, [userId]);

  
    // Scroll to top whenever the route changes
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
  


  const getSubscriptionColor = () => {
    switch (subscriptionStatus.toLowerCase()) {
      case 'free':
        return '#02db2a'; // Custom hex for green
      case 'pro':
        return '#0967ff'; // Custom hex for blue
      case 'elite':
        return 'orange'; // Custom hex for orange
      case 'unlimited':
        return '#de00de'; // Custom hex for purple
      default:
        return '#808080'; // Custom hex for gray
    }
  };




  useEffect(() => {
    const trackPageView = (url) => {
      if (window.gtag) {
        // Track the general page view
        window.gtag('config', 'G-6CYJ9YMLWP', {
          page_path: url,
        });
  
        // Custom events for specific pages
        if (url === '/') {
          window.gtag('event', 'homepage_view', {
            event_category: 'Page Views',
            event_label: 'Home Page',
          });
        } else if (url === '/feed') {
          window.gtag('event', 'feedpage_view', {
            event_category: 'Page Views',
            event_label: 'Feed Page',
          });
        } else if (url.startsWith('/poll/')) {
          window.gtag('event', 'standalone_poll_view', {
            event_category: 'Page Views',
            event_label: 'Standalone Poll',
          });
        }
      }
    };
  
    // Track the initial page load
    trackPageView(window.location.pathname + window.location.search);
  
    // Track page changes
    const unlisten = () => {
      trackPageView(window.location.pathname + window.location.search);
    };
    return () => {
      unlisten();
    };
  }, [location]);
  


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        if (decoded.exp * 1000 > Date.now()) {
          setIsLoggedIn(true);
          setUserId(decoded.userId);
          fetch(`/auth/user/${decoded.userId}`)
            .then((response) => response.json())
            .then((data) => {
              logger.log('User data fetched from backend:', data);
              setUsername(data.username);
              setUserEmail(data.email);
              setFirstName(data.firstName || ''); // Set firstName
              setLastName(data.lastName || '');  // Set lastName
              setHash(data.hash || '');         // Add this line to set the hash
            })
            .catch((error) => logger.error('Error fetching user data:', error))
            .finally(() => setIsLoadingUser(false));
        } else {
          localStorage.removeItem('token');
          setIsLoadingUser(false);
        }
      } catch (error) {
        localStorage.removeItem('token');
        setIsLoadingUser(false);
      }
    } else {
      setIsLoadingUser(false);
    }
  }, [navigate]);

   // Combine firstName and lastName into a full name
   const fullName = `${firstName} ${lastName}`.trim();
  

  const handleSignUp = (token, userId, username) => {
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
    setUserId(userId);
    setUsername(username);
    navigate('/dashboard/main'); // Update this to redirect to /dashboard/main
  };
  
  const handleLogin = (token, userId, username) => {
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
    setUserId(userId);
    setUsername(username);
    navigate('/dashboard/main'); // Update this to redirect to /dashboard/main
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setUserId(null);
    setUsername('');
    setIsMenuOpen(false);
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  

  useEffect(() => {
    if (isEmbedRoute) {
      document.body.classList.add('embed-poll-body');
    } else {
      document.body.classList.remove('embed-poll-body');
    }
  }, [isEmbedRoute]);

  return (
    <div className="App">
      
      {isLoadingUser ? (
        <div>Loading...</div> // Or a spinner, etc.
      ) : (
        <>
{!isHomePage &&
 location.pathname !== '/brands' && // Add this line
 location.pathname !== '/signup' &&
 location.pathname !== '/login' &&
 location.pathname !== '/dashboard/main' &&
 location.pathname !== '/dashboard' &&
 !location.pathname.startsWith('/dashboard/') && // Add this line
 !location.pathname.startsWith('/poll') &&
 !location.pathname.startsWith('/review') && (
  // Render the navbar here
    <div className="bar-container">

    <nav className="navbar">
        <img src={Logo} alt="Logo" className="home-logo" onClick={() => navigate('/')} />
        
        <div className="hamburger-menu" onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
        </div>

        <div className={`nav-capsule ${isMenuOpen ? 'open' : ''}`}>
            <ul className="nav-links">
                <li><NavLink to="/" end activeClassName="active">Home</NavLink></li>
                {/* <li><NavLink to="/brands" end activeClassName="active">Brands</NavLink></li> */}
                <li><NavLink to="/explore" activeClassName="active">Explore</NavLink></li>
                <li><NavLink to="/about" activeClassName="active">About</NavLink></li>
                <li><NavLink to="/contact" activeClassName="active">Contact</NavLink></li>
                <li><NavLink to="/faqs" activeClassName="active">FAQs</NavLink></li>
                <li><NavLink to="/team" activeClassName="active">Team</NavLink></li>
            </ul>
        </div>

        <div className="auth-capsule">
            {isLoggedIn ? (
                <button onClick={() => navigate('/dashboard/main')} className="signup">Go to Dashboard</button>
            ) : (
                <>
                    <button onClick={() => navigate('/signup')} className="signup">Sign Up</button>
                    <button onClick={() => navigate('/login')} className="login">Log In</button>
                </>
            )}
        </div>
    </nav>
    </div>
)}

          {/* Main Content */}
          <main className={`App-main ${shouldHideTopBar ? 'without-topbar' : ''}`}>
          <Routes>
  <Route exact path="/" element={<Home isLoggedIn={isLoggedIn} />} />
  <Route exact path="/Brands" element={<HomeBrands isLoggedIn={isLoggedIn} />} />

  <Route
    path="/explore"
    element={<Feed isLoggedIn={isLoggedIn} userId={userId} username={username} isProfileView={false} />}
  />

  <Route
    path="/review/:hash"
    element={<ReviewPoll isLoggedIn={isLoggedIn} userId={userId} username={username} />}
  />


  <Route
    path="/poll/:hash"
    element={<StandalonePoll isLoggedIn={isLoggedIn} userId={userId} username={username} />}
  />
  <Route
    path="/embed/:hash"
    element={<EmbedPoll isLoggedIn={isLoggedIn} userId={userId} username={username} />}
  />
  <Route path="/verify-email" element={<VerifyEmail />} />
  <Route path="/initiate-reset-password" element={<InitiateResetPassword />} />
  <Route path="/reset-password/:token" element={<ResetPassword />} />
  <Route
    path="/dashboard/:hash"
    element={<LiveDashboard userId={userId} username={username} userEmail={userEmail} />}
  />
  <Route
    path="/connectedpoll/:hash"
    element={<ConnectedPoll isLoggedIn={isLoggedIn} userId={userId} username={username} />}
  />
  <Route path="/payment-success" element={<PaymentSuccess />} />
  <Route path="/signup" element={<SignUpPage onSignUp={handleSignUp} />} />
  <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
  <Route path="/contact" element={<Contact />} />
  <Route path="/about" element={<About isLoggedIn={isLoggedIn} />} />
  <Route path="/user-agreement" element={<UserAgreement isLoggedIn={isLoggedIn} />} />
  <Route path="/privacy" element={<Privacy />} />
  <Route path="/terms" element={<Terms />} />
  <Route path="/faqs" element={<FAQs />} />
  <Route path="/team" element={<Team />} />

  <Route
    path="/dashboard/main"
    element={
      username === 'Admin' ? (
        <AdminDashboard /> // Render the AdminDashboard for admin user
      ) : subscriptionStatus === 'Enterprise' ? (
        <BrandDashboard
          userId={userId}
          username={username}
          userEmail={userEmail}
          isProfileView={true}
          isLoggedIn={isLoggedIn}
          subscriptionStatus={subscriptionStatus}
          userHash={hash} // Pass the hash prop here
        />
      ) : (
        <MainDashboard
          userId={userId}
          username={username}
          userEmail={userEmail}
          isProfileView={true}
          isLoggedIn={isLoggedIn}
          subscriptionStatus={subscriptionStatus}
          name={fullName} // Pass the full name here
          userHash={hash} // Pass the hash prop here

        />
      )
    }
  />
</Routes>

          </main>
  
          
        </>
      )}
    </div>
  );
  
  
  
}

window.Poll = Poll;

export default App;
