import BrandAd from "./BrandAd";

const BrandAdvertisements = ({ ads }) => {
  return (
    <div
      className="brand-advertisements"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap", // Allows wrapping if there are multiple ads
        gap: "20px", // Adds spacing between the ads
        padding: "20px", // Optional padding around the container
      }}
    >
      {ads.map((ad, index) => (
         <BrandAd
         key={index}
         description={ad.description}
         adLink={ad.link}
         image={ad.image}
         brandName={ad.brandName} // Pass the brandName
         ctaText={ad.ctaText} // Pass the ctaText
       />
      ))}
    </div>
  );
};

export default BrandAdvertisements;
