import React, { useState, useEffect } from 'react';
import './Home.css'; // Ensure your styles are properly imported
import { useNavigate,  useSearchParams, Link } from 'react-router-dom';
import Calculator from "./Calculator";
import PoweredBy from '../assets/PoweredBy.png';




// Import images from the assets folder
import Logo from '../assets/logo.png';
import HeroImg from '../assets/HeroBrands.png';
import YouTubeIcon from '../assets/youtube.png';
import FacebookIcon from '../assets/facebook.png';
import InstagramIcon from '../assets/instagram.png';
import LinkedInIcon from '../assets/linkedin.png';
import PinterestIcon from '../assets/pinterest.png';
import RedditIcon from '../assets/reddit.png';
import SnapchatIcon from '../assets/snapchat.png';
import TikTokIcon from '../assets/tiktok.png';
import XIcon from '../assets/x.png';
import OpinionsImg from '../assets/opinions.png';
import FlexibilityImg from '../assets/flexibility.png';
import HandledImg from '../assets/handled.png';
import ChatWidgetIcon from "../assets/WidgetIcon.png"; // Replace with your widget icon image
import Segment from "../assets/segment.png"; // Replace with your widget icon image
import Tiktok from "../assets/Parter-TikTok.png"; // Replace with your widget icon image
import Taboola from "../assets/Parter-Taboola.png"; // Replace with your widget icon image
import Meta from "../assets/Parter-Meta.png"; // Replace with your widget icon image
import CreationImg from '../assets/PollCreationBrands.png';
import TwoWayStreet from '../assets/TwoWayStreet.png';
import PaymentImg from '../assets/Payment.png';





const HomeBrands = ({ isLoggedIn }) => {

  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false); // State to toggle the lightbox
  const [searchParams] = useSearchParams();
  const [isForCreators, setIsForCreators] = useState(false); // Default is "For Brands"




  useEffect(() => {
    if (searchParams.get('openCalculator') === 'true') {
      setIsLightboxOpen(true);
    }
  }, [searchParams]);

  const goToSignUp = () => {
    navigate('/signup');
  };
  const goToDashboard = () => {
    navigate('/dashboard/main');
  };

  const goToLogIn = () => {
    navigate('/login');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    const navbar = document.querySelector('.navbar');
    if (navbar) {
      navbar.classList.toggle('active');
    }
  };

  const poweredByWrapperStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  };
  
  const poweredByStyle = {
    height: '30px', // Set the width of the powered by image
    display: 'block',
    marginTop: '10px'
  };


  const toggleLightbox = () => {
    setIsLightboxOpen(!isLightboxOpen); // Toggle the lightbox state
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    const navbar = document.querySelector('.navbar');
    if (navbar) {
      navbar.classList.remove('active');
    }
  };
  
  
  
  return (
    <div className="home-page">
    <div className="background-wrapper-2">
      <div className="container">
        <nav className="navbar">
          <img src={Logo} alt="Logo" className="home-logo" />





          <div className="hamburger-menu" onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div className={`nav-capsule ${isMenuOpen ? 'open' : ''}`}>
          <ul className="nav-links">
         


  <li><a href="/" onClick={closeMenu}>Creators</a></li>
  <li><a href="/brands" onClick={closeMenu} className="active">Brands</a></li>

  <li><a href="/about" onClick={closeMenu}>About</a></li>
  <li><a href="/contact" onClick={closeMenu}>Contact</a></li>
  <li><a href="/FAQs" onClick={closeMenu}>FAQs</a></li>
  <li><a href="/Team" onClick={closeMenu}>Team</a></li>

  {window.innerWidth < 1024 && (
    <>
      <li>
        <a  onClick={goToLogIn} className="custom-login-button">Login</a>
      </li>
      <li>
        <a  onClick={goToSignUp} className="custom-signup-button">Signup</a>
      </li>
    </>
  )}
</ul>


          </div>

          <div className="auth-capsule">
            <button onClick={goToSignUp} className="signup">Sign Up</button>
            <button onClick={goToLogIn} className="login">Log In</button>
          </div>
        </nav>

          {/* Chat Widget */}
          <div className="chat-widget" onClick={toggleLightbox}>
          <img src={ChatWidgetIcon} alt="Chat Widget" />
          <span>Calculate Your Influence</span>
        </div>

        {/* Lightbox */}
        {isLightboxOpen && (
          <div className="lightbox-overlay" onClick={toggleLightbox}>
            <div
              className="lightbox-content"
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
            >
              <button className="close-lightbox" onClick={toggleLightbox}>
                &times;
              </button>
              <Calculator />
              
            </div>
          </div>
        )}
        

<header className="hero-2">

  <h1>
    Engagement Rules All
  </h1>
  <p>
  Opinari is the all-in-one platform that ensures influencer marketing delivers results. Use interactive polls to engage audiences first, then deliver your brand’s tailored ads, discounts, or offers to highly motivated buyers—powered by top influencers from leading agency partnerships.   
  
  </p>
  <div className="cta-button-container">
    <button
      onClick={isLoggedIn ? goToDashboard : goToSignUp}
      className="cta-button"
    >
      {isLoggedIn ? 'Go to Dashboard' : 'Start Free'}
    </button>
    {!isLoggedIn && (
      <button
        onClick={() => window.open('https://www.youtube.com/watch?v=sbK293MooB8', '_blank')}
        className="demo-button"
      >
        See Demo
      </button>
    )}
  </div>
</header>

      </div>

      <div className="container">
  <div className="card-container">
    <a href="https://opinari.io/poll/121530a4b8d4" target="_blank" rel="noopener noreferrer">
      <img src={HeroImg} alt="Hero" />
    </a>
  </div>
</div>


      <div className="custom-shape-divider-top-1730048845">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>

      <div className="full-width-wrapper">
        <div className="social-icons-container">
          <img src={YouTubeIcon} alt="YouTube" />
          <img src={FacebookIcon} alt="Facebook" />
          <img src={InstagramIcon} alt="Instagram" />
          <img src={LinkedInIcon} alt="LinkedIn" />
          <img src={PinterestIcon} alt="Pinterest" className="pinterest-icon" />
          <img src={RedditIcon} alt="Reddit" />
          <img src={SnapchatIcon} alt="Snapchat" />
          <img src={TikTokIcon} alt="TikTok" />
          <img src={XIcon} alt="X" />
        </div>

        <div className="custom-grid-container">
          <div className="custom-left-section">
            <div className="custom-header">
              <h1>Two Way Street - Sell and Get Insight</h1>
              <p>
              Opinari drives higher conversions through polling while equipping brands with valuable first-party data for future campaigns. Gain unbiased insights into key trends like audience segments, demographics, locations, and other KPIs. With fully anonymous voting, the data reflects genuine audience sentiment, providing actionable resources to optimize and scale your influencer marketing efforts.
              </p>
              <button className="cta-button-sections" onClick={goToSignUp}>Get Started</button>

            </div>



            <div className="segment-container">
            <a href="https://opinari.io/poll/121530a4b8d4" target="_blank" rel="noopener noreferrer">
              <img src={TwoWayStreet} alt="Hero" />
            </a>
          </div>

            
          </div>

         
        </div>
      </div>

      <div className="custom-shape-divider-top">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>

      

      <div className="full-width-wrapper-sections">
  <div className="gradient-section-3">
    <h2>Poll Creation</h2>
    <p>
    With over 50+ customizable answers available per poll, creating highly relevant and engaging polls is easy and time efficient. Guided AI assists with poll creation by suggesting options and streamlining the process, helping you build better polls. *Implement your ads and offerings by simply uploading a photo, description and link to your offerings.
    </p>
    <button className="cta-button-sections" onClick={() => navigate('/signup')}>Start Now</button>
    <img src={CreationImg} alt="Creation" />
  </div>

  

  
</div>


<div className="custom-shape-divider-top-1730048845">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>

      <div className="full-width-wrapper">
       

        <div className="custom-grid-container">
          <div className="custom-left-section">
            <div className="custom-header">
              <h1>Success-Driven Payments</h1>
              <p>
              Opinari partners with influencer agencies to ensure you only pay for results. We earn from ad clicks after votes, focusing your budget on engaged audiences. With no setup fees, you can allocate part of your budget to Opinari while keeping flexibility for other initiatives. Once your budget is reached, polls stay active, data is collected, and ads stop—maximizing ROI without disrupting the audience experience.              </p>
              <button className="cta-button-sections" onClick={goToSignUp}>Get Started</button>

            </div>



            <div className="segment-container">
            <a href="https://opinari.io/poll/121530a4b8d4" target="_blank" rel="noopener noreferrer">
              <img src={PaymentImg} alt="Hero" />
            </a>
          </div>

            
          </div>

         
        </div>
      </div>

      <div className="custom-shape-divider-top">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>

     
    </div>




    <div className="partners-container">
          <img src={Taboola} alt="YouTube" />
          <img src={Meta} alt="Facebook" />
          <img src={Tiktok} alt="Instagram" />
        </div>


    <footer className="footer">
        <div className="footer-container">
          <div className="footer-logo-section">
            <img src={Logo} alt="Logo" className="footer-logo" />
          </div>

          <div className="footer-links">
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/about">About</a></li>
              <li><a href="/contact">Contact</a></li>
              <li><a href="/FAQs">FAQs</a></li>
            </ul>
          </div>

          <div className="footer-socials">
            <a href="https://www.youtube.com/@Opinaripolls" target="_blank" rel="noopener noreferrer">
              <img src={YouTubeIcon} alt="YouTube" />
            </a>
            <a href="https://www.instagram.com/opinari.io?igsh=YzFwNjJ6bWF1end0" target="_blank" rel="noopener noreferrer">
              <img src={InstagramIcon} alt="Instagram" />
            </a>
            <a href="https://www.linkedin.com/company/104938617" target="_blank" rel="noopener noreferrer">
              <img src={LinkedInIcon} alt="LinkedIn" />
            </a>
        </div>

        </div>

        <div className="footer-bottom">
          <p>&copy; 2025 OPINARI. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default HomeBrands;
