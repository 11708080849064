import React, { useState } from 'react';
import './AdminDashboard.css';

function AdminDashboard() {
  const [logs, setLogs] = useState([]);
  const [payoutSummary, setPayoutSummary] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const logMessage = (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
  };

  const handleInitializePayouts = async () => {
    setIsProcessing(true);
    setLogs([]); // Clear previous logs
    try {
      const response = await fetch('/api/initialize-payouts', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });

      const result = await response.json();

      if (response.ok) {
        logMessage('✅ Payouts initialized successfully!');
        result.logs.forEach((log) => logMessage(log));
        setPayoutSummary(result.summary); // Store the summary
        setIsInitialized(true); // Mark as initialized
      } else {
        logMessage(`⚠️ Error initializing payouts: ${result.error}`);
      }
    } catch (error) {
      logMessage(`❌ Unexpected error: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleConfirmPayouts = async () => {
    if (!isInitialized) {
      logMessage('⚠️ Please initialize payouts first!');
      return;
    }
  
    setIsProcessing(true);
    setLogs([]); // Clear previous logs
    try {
      const response = await fetch('/api/confirm-payouts', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ payoutSummary }), // Send the summary for confirmation, including userId
      });
  
      const result = await response.json();
  
      if (response.ok) {
        logMessage('✅ Payouts confirmed successfully!');
        result.logs.forEach((log) => logMessage(log));
        setPayoutSummary([]); // Clear the summary after confirmation
        setIsInitialized(false); // Reset initialization
      } else {
        logMessage(`⚠️ Error confirming payouts: ${result.error}`);
      }
    } catch (error) {
      logMessage(`❌ Unexpected error: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };
  

  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>
      <button
        className="initialize-payouts-button"
        onClick={handleInitializePayouts}
        disabled={isProcessing}
      >
        {isProcessing ? 'Processing...' : 'Initialize Payouts'}
      </button>
      {isInitialized && (
        <button
          className="confirm-payouts-button"
          onClick={handleConfirmPayouts}
          disabled={isProcessing}
        >
          {isProcessing ? 'Processing...' : 'Confirm Payouts'}
        </button>
      )}
      <div className="summary-section">
        <h2>Payout Summary</h2>
        {payoutSummary.length > 0 ? (
          <table className="summary-table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Email</th>
              <th>Poll Payout ($)</th>
              <th>Affiliate Payout ($)</th>
              <th>Total Payout ($)</th>
            </tr>
          </thead>
          <tbody>
            {payoutSummary.map((summary, index) => (
              <tr key={index}>
                <td>{summary.username}</td>
                <td>{summary.email}</td>
                <td>{summary.regularPayout.toFixed(2)}</td>
                <td>{summary.affiliatePayout.toFixed(2)}</td>
                <td>{summary.totalPayout.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        
        ) : (
          <p>No payouts initialized yet.</p>
        )}
      </div>
      <div className="log-section">
        <h2>Logs</h2>
        <div className="log-messages">
          {logs.length > 0 ? (
            logs.map((log, index) => <p key={index}>{log}</p>)
          ) : (
            <p>No logs yet.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
