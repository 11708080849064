import React, { useState } from 'react';
import FolderImage from '../assets/Folder.png'; // Import the folder image
import './Campaigns.css'; // Import the CSS for styling
import OptionsModal from './OptionsModal'; // Import the reusable modal

const Campaigns = ({ campaigns, isLoading, onCampaignSelect, onCreateCampaign }) => {
  const [isModalOpen, setIsModalOpen] = useState(false); // Manage modal visibility
  const [newCampaignName, setNewCampaignName] = useState(''); // Track new campaign name

  const handleSaveCampaign = async (values) => {
    const campaignName = values.campaignName;
    if (!campaignName.trim()) {
      alert('Campaign name cannot be empty!');
      return;
    }

    try {
      await onCreateCampaign(campaignName); // Call the parent-provided creation logic
      setIsModalOpen(false); // Close the modal
      setNewCampaignName(''); // Reset the input
    } catch (err) {
      console.error('Error creating campaign:', err);
      alert('Failed to create campaign. Please try again.');
    }
  };

  if (isLoading) {
    return <div className="loading">Loading campaigns...</div>;
  }

  return (
    <div className="campaigns-container">
      <div className="folder-grid">
        {campaigns &&
          campaigns.map((campaign) => (
            <div
              key={campaign._id}
              className="campaign-folder"
              onDoubleClick={() => onCampaignSelect(campaign)}
            >
              <img src={FolderImage} alt="Folder" className="folder-icon" />
              <div className="campaign-name">{campaign.campaignName}</div>
            </div>
          ))}

        <div className="add-campaign-folder">
          <button
            className="add-campaign-button"
            onClick={() => setIsModalOpen(true)}
          >
            +
          </button>
        </div>
      </div>

      {/* OptionsModal for creating a new campaign */}
      <OptionsModal
        isOpen={isModalOpen}
        title="Create New Campaign"
        fields={[
          {
            type: 'text',
            name: 'campaignName',
            label: 'Campaign Name',
            defaultValue: '',
          },
        ]}
        onSave={handleSaveCampaign}
        onCancel={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default Campaigns;
