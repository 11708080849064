import React, { useState, useEffect } from 'react';
import './Report.css';

const Report = ({ userId, reportId, onReportNameUpdate }) => {
  const [reportData, setReportData] = useState(null);
  const [pollData, setPollData] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [isGenerated, setIsGenerated] = useState(false);

  useEffect(() => {
    if (reportId) {
      fetchReportData(reportId);
    }
  }, [reportId]);

  const fetchReportData = async (reportId) => {
    try {
      const response = await fetch(`/api/reports/${reportId}`);
      if (!response.ok) {
        throw new Error(
          `Failed to fetch report data: HTTP ${response.status} ${response.statusText}`
        );
      }

      const data = await response.json();
      setReportData(data);

      if (data.reportName && onReportNameUpdate) {
        onReportNameUpdate(data.reportName);
      }

      if (data.pollId) {
        fetchPollData(data.pollId);
      } else {
        console.warn('No pollId found in report data');
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching report data:', error.message);
      setError(error.message);
      setLoading(false);
    }
  };

  const fetchPollData = async (pollId) => {
    try {
      const response = await fetch(`/api/report/dump-vote-data?pollId=${pollId}`);
      const data = await response.json();
      setPollData(data);
    } catch (error) {
      console.error('Error fetching poll data:', error);
      setError(error.message);
    }
  };

  const handleGenerateReport = () => {
    if (pollData) {
      setIsGenerated(true);
    } else {
      setError('Poll data is missing. Unable to generate the report.');
    }
  };

  /**
   * Group votes by main option (label), then within each option,
   * group again by confidence label. 
   */
  const aggregateVotes = (votes) => {
    const labels = pollData?.labels || [];                // e.g. ["Agree", "Disagree"]
    const confidenceLabels = pollData?.confidenceLabels || []; 
    // e.g. [
    //   ["Strongly Agree", "Agree", "Neutral", "Disagree", "Strongly Disagree"],
    //   ["Another set", "if you had more main options", ... ]
    // ]

    // 1) Group all votes by the main label (vote.choiceLabel)
    const groupedVotes = votes.reduce((acc, vote) => {
      const { choiceLabel } = vote;
      if (!acc[choiceLabel]) {
        acc[choiceLabel] = { count: 0, hues: [], votes: [] };
      }
      acc[choiceLabel].count += 1;
      acc[choiceLabel].hues.push(vote.choice);  // Hue is stored in vote.choice
      acc[choiceLabel].votes.push(vote);
      return acc;
    }, {});

    // 2) For each group, figure out the correct confidence sub-array
    const sortedGroups = Object.entries(groupedVotes).map(([label, data]) => {
      // Attempt to find the label index in pollData.labels
      const optionIndex = labels.indexOf(label);
      if (optionIndex < 0) {
        console.warn(`Label "${label}" not found in pollData.labels:`, labels);
      }

      // Safely grab the sub-array for that index, or empty array if not found
      const optionConfidenceLabels = 
        (optionIndex >= 0 && confidenceLabels[optionIndex]) 
        ? confidenceLabels[optionIndex] 
        : [];

      // Now group again by confidence
      const confidenceGroups = data.votes.reduce((confAcc, vote) => {
        // If your DB stores confidence as 1..5, subtract 1 to get 0..4
        const confidenceIndex = vote.confidence - 1;

        // If the confidenceIndex is out of range, fallback or warn
        if (
          confidenceIndex < 0 ||
          confidenceIndex >= optionConfidenceLabels.length
        ) {
          console.warn(
            `Confidence index out of range: ${confidenceIndex} (vote.confidence = ${vote.confidence}).`,
            'optionConfidenceLabels:',
            optionConfidenceLabels
          );
        }

        // Grab the actual label (or fallback text)
        const confidenceLabel =
          optionConfidenceLabels[confidenceIndex] ||
          `Confidence ${vote.confidence}`;

        if (!confAcc[confidenceLabel]) {
          confAcc[confidenceLabel] = { count: 0, votes: [] };
        }
        confAcc[confidenceLabel].count += 1;
        confAcc[confidenceLabel].votes.push(vote);
        return confAcc;
      }, {});

      return {
        label,
        count: data.count,
        avgHue: averageHues(data.hues),
        confidenceGroups,
      };
    });

    // 3) Sort the groups by the largest # of votes
    return sortedGroups.sort((a, b) => b.count - a.count);
  };

  /**
   * Averages out the array of numeric hue values so the
   * bar color is the average hue for that main option.
   */
  const averageHues = (hues) => {
    let sinSum = 0;
    let cosSum = 0;

    hues.forEach((hue) => {
      const radians = (hue * Math.PI) / 180;
      sinSum += Math.sin(radians);
      cosSum += Math.cos(radians);
    });

    const avgRadians = Math.atan2(sinSum, cosSum);
    return ((avgRadians * 180) / Math.PI + 360) % 360;
  };

  /**
   * Renders a bar for each confidence label group within a main option
   */
  const renderConfidenceBars = (confidenceGroups, avgHue, choiceLabel, totalVotesForOption) => {
    return Object.entries(confidenceGroups).map(([confidenceLabel, group]) => {
      const percentage = ((group.count / totalVotesForOption) * 100).toFixed(1);

      return (
        <div className="report-bar-container" key={`confidence-${confidenceLabel}`}>
          <span className="report-bar-title">{confidenceLabel}</span>
          <div className="report-bar-bg">
            <div
              className="report-bar"
              style={{
                width: `${percentage}%`,
                backgroundColor: `hsl(${avgHue}, 100%, 50%)`,
              }}
            />
          </div>
          <span className="report-bar-info">
            {group.count} votes ({percentage}%)
          </span>
        </div>
      );
    });
  };

  return (
    <div className="report-container">
      <h2 className="report-title">Coming Soon! </h2>
      <h3 className="report-sub">We're currently revamping reports to provide you with even deeper insights into your poll data.</h3>
      {/* {error && <p style={{ color: 'red' }}>{error}</p>}
      {loading ? (
        <p>Loading report...</p>
      ) : (
        <>
          {isGenerated ? (
            pollData && (
              <div>
                <h2>{pollData.pollId.question}</h2>
                {aggregateVotes(pollData.votes).map((item) => (
                  <div key={item.label} className="report-option-group">
                    <h3 className="report-option-title">
                      {item.label} ({item.count} votes)
                    </h3>
                    {renderConfidenceBars(
                      item.confidenceGroups,
                      item.avgHue,
                      item.label,
                      item.count
                    )}
                  </div>
                ))}
              </div>
            )
          ) : (
            <button onClick={handleGenerateReport} className="generate-report-button">
              Generate Report
            </button>
          )}
        </>
      )} */}
    </div>
  );
};

export default Report;
