import React, { useState } from 'react';
import './OptionsModal.css';

const OptionsModal = ({ 
  title, 
  fields = [], 
  onSave, 
  onCancel, 
  isOpen, 
  saveButtonText = "Save" // Default to "Save" if not provided
}) => {
  const [values, setValues] = useState(
    fields.reduce((acc, field) => ({ ...acc, [field.name]: field.defaultValue || '' }), {})
  );

  if (!isOpen) return null;

  const handleChange = (name, value) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    onSave(values);
  };

  return (
    <div className="options-modal-backdrop">
      <div className="options-modal-container">
        <div className="options-modal-header">
          <h2>{title}</h2>
        </div>
        <div className="options-modal-body">
          {fields.map((field, index) => {
            switch (field.type) {
              case 'text':
                return (
                  <div key={index} className="options-modal-field">
                    <label>{field.label}</label>
                    <input
                      type="text"
                      value={values[field.name]}
                      onChange={(e) => handleChange(field.name, e.target.value)}
                    />
                  </div>
                );
              case 'toggle':
                return (
                  <div key={index} className="options-modal-field">
                    <label>{field.label}</label>
                    <input
                      type="checkbox"
                      checked={values[field.name]}
                      onChange={(e) => handleChange(field.name, e.target.checked)}
                    />
                  </div>
                );
              case 'dropdown':
                return (
                  <div key={index} className="options-modal-field">
                    <label>{field.label}</label>
                    <select
                      value={values[field.name]}
                      onChange={(e) => handleChange(field.name, e.target.value)}
                    >
                      {field.options.map((option, idx) => (
                        <option key={idx} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                );
              case 'textarea':
                return (
                  <div key={index} className="options-modal-field">
                    <label>{field.label}</label>
                    <textarea
                      value={values[field.name]}
                      onChange={(e) => handleChange(field.name, e.target.value)}
                    />
                  </div>
                );
              default:
                return null;
            }
          })}
        </div>
        <div className="options-modal-footer">
          <button className="options-modal-cancel" onClick={onCancel}>
            Cancel
          </button>
          <button className="options-modal-save" onClick={handleSave}>
            {saveButtonText} {/* Use the customizable save button text */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OptionsModal;
