import React, { useState, useEffect } from 'react';
import './Home.css'; // Ensure your styles are properly imported
import { useNavigate,  useSearchParams, Link } from 'react-router-dom';
import Calculator from "./Calculator";
import PoweredBy from '../assets/PoweredBy.png';





// Import images from the assets folder
import Logo from '../assets/logo.png';
import HeroImg from '../assets/Hero.png';
import YouTubeIcon from '../assets/youtube.png';
import FacebookIcon from '../assets/facebook.png';
import InstagramIcon from '../assets/instagram.png';
import LinkedInIcon from '../assets/linkedin.png';
import PinterestIcon from '../assets/pinterest.png';
import RedditIcon from '../assets/reddit.png';
import SnapchatIcon from '../assets/snapchat.png';
import TikTokIcon from '../assets/tiktok.png';
import XIcon from '../assets/x.png';
import OpinionsImg from '../assets/opinions.png';
import FlexibilityImg from '../assets/flexibility.png';
import HandledImg from '../assets/handled.png';
import CreationImg from '../assets/PollCreation.png';

import ChatWidgetIcon from "../assets/WidgetIcon.png"; // Replace with your widget icon image
import Segment from "../assets/segment.png"; // Replace with your widget icon image
import Tiktok from "../assets/Parter-TikTok.png"; // Replace with your widget icon image
import Taboola from "../assets/Parter-Taboola.png"; // Replace with your widget icon image
import Meta from "../assets/Parter-Meta.png"; // Replace with your widget icon image
import TrackMoney from "../assets/TrackMoney.png"; // Replace with your widget icon image



const Home = ({ isLoggedIn }) => {

  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false); // State to toggle the lightbox
  const [searchParams] = useSearchParams();
  const [isForCreators, setIsForCreators] = useState(true);



  useEffect(() => {
    if (searchParams.get('openCalculator') === 'true') {
      setIsLightboxOpen(true);
    }
  }, [searchParams]);

  const goToSignUp = () => {
    navigate('/signup');
  };
  const goToExplore = () => {
    navigate('/explore');
  };
  const goToDashboard = () => {
    navigate('/dashboard/main');
  };

  const goToLogIn = () => {
    navigate('/login');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    const navbar = document.querySelector('.navbar');
    if (navbar) {
      navbar.classList.toggle('active');
    }
  };

  const poweredByWrapperStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  };
  
  const poweredByStyle = {
    height: '30px', // Set the width of the powered by image
    display: 'block',
    marginTop: '10px'
  };


  const toggleLightbox = () => {
    setIsLightboxOpen(!isLightboxOpen); // Toggle the lightbox state
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    const navbar = document.querySelector('.navbar');
    if (navbar) {
      navbar.classList.remove('active');
    }
  };
  
  
  
  return (
    <div className="home-page">
    <div className="background-wrapper">
      <div className="container">
        <nav className="navbar">
          <img src={Logo} alt="Logo" className="home-logo" />

 




          <div className="hamburger-menu" onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div className={`nav-capsule ${isMenuOpen ? 'open' : ''}`}>
          <ul className="nav-links">
         


          <li><a href="/" onClick={closeMenu} className="active">Home</a></li>
          {/* <li><a href="/brands" onClick={closeMenu}>Brands</a></li> */}
          <li><a href="/explore" onClick={closeMenu}>Explore</a></li>

  <li><a href="/about" onClick={closeMenu}>About</a></li>
  <li><a href="/contact" onClick={closeMenu}>Contact</a></li>
  <li><a href="/FAQs" onClick={closeMenu}>FAQs</a></li>
  <li><a href="/Team" onClick={closeMenu}>Team</a></li>

  {window.innerWidth < 1024 && (
    <>
      <li>
        <a  onClick={goToLogIn} className="custom-login-button">Login</a>
      </li>
      <li>
        <a  onClick={goToSignUp} className="custom-signup-button">Signup</a>
      </li>
    </>
  )}
</ul>


          </div>

          <div className="auth-capsule">
            <button onClick={goToSignUp} className="signup">Sign Up</button>
            <button onClick={goToLogIn} className="login">Log In</button>
          </div>
        </nav>

          {/* Chat Widget */}
          <div className="chat-widget" onClick={toggleLightbox}>
          <img src={ChatWidgetIcon} alt="Chat Widget" />
          <span>Calculate Your Influence</span>
        </div>

        {/* Lightbox */}
        {isLightboxOpen && (
          <div className="lightbox-overlay" onClick={toggleLightbox}>
            <div
              className="lightbox-content"
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
            >
              <button className="close-lightbox" onClick={toggleLightbox}>
                &times;
              </button>
              <Calculator />
              
            </div>
          </div>
        )}
        

<header className="hero">

  <h1>
    Run Polls, Get Data, Make Money.
  </h1>
  <p>
  OPINARI is the fastest way to collect audience feedback,   
    <span className="line-break"></span> then display personalized ads to earn money on every click. 
  </p>
  <div className="cta-button-container">
    <button
      onClick={isLoggedIn ? goToDashboard : goToSignUp}
      className="cta-button"
    >
      {isLoggedIn ? 'Go to Dashboard' : 'Start Free'}
    </button>
    {!isLoggedIn && (
      <button
        onClick= {goToExplore}
        className="demo-button"
      >
        Explore
      </button>
    )}
  </div>
</header>

      </div>

      <div className="container">
  <div className="card-container">
    <a href="https://opinari.io/poll/121530a4b8d4" target="_blank" rel="noopener noreferrer">
      <img src={HeroImg} alt="Hero" />
    </a>
  </div>
</div>


      <div className="custom-shape-divider-top-1730048845">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>

      <div className="full-width-wrapper">
        <div className="social-icons-container">
          <img src={YouTubeIcon} alt="YouTube" />
          <img src={FacebookIcon} alt="Facebook" />
          <img src={InstagramIcon} alt="Instagram" />
          <img src={LinkedInIcon} alt="LinkedIn" />
          <img src={PinterestIcon} alt="Pinterest" className="pinterest-icon" />
          <img src={RedditIcon} alt="Reddit" />
          <img src={SnapchatIcon} alt="Snapchat" />
          <img src={TikTokIcon} alt="TikTok" />
          <img src={XIcon} alt="X" />
        </div>

        <div className="custom-grid-container">
          <div className="custom-left-section">
            <div className="custom-header">
              <h1>Grow Your Money</h1>
              <p>
              See earnings, payments, revenue per click, click through rates, voting trends and more. Put a poll on every post and not only EARN, but LEARN what your audience loves most. We provide a 50/50 advertising revenue share monthly with all of our users.              </p>
              <button className="cta-button-sections" onClick={goToSignUp}>Get Started</button>

            </div>



            <div className="segment-container">
    <a href="https://opinari.io/poll/121530a4b8d4" target="_blank" rel="noopener noreferrer">
      <img src={TrackMoney} alt="Hero" />
    </a>
  </div>

            
          </div>

         
        </div>
      </div>

      <div className="custom-shape-divider-top">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>

      

      <div className="full-width-wrapper-sections">
  <div className="gradient-section-3">
    <h2>Poll Creation in Seconds    </h2>
    <p>
    In seconds, our guided AI assists you in creating customized polls containing over 50+ possible answers. Share the poll with your audience across ALL social channels with ONE LINK. Create multiple polls to cover everything you want to know about your audience, followers, subscribers, fans or whomever you like.    </p>
    <button className="cta-button-sections" onClick={() => navigate('/signup')}>Start Now</button>
    <img src={CreationImg} alt="Creation" />
  </div>

  

  
</div>


<div className="custom-shape-divider-top-1730048845">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>



      <div className="full-width-wrapper">
        <div className="social-icons-container">
          <img src={YouTubeIcon} alt="YouTube" />
          <img src={FacebookIcon} alt="Facebook" />
          <img src={InstagramIcon} alt="Instagram" />
          <img src={LinkedInIcon} alt="LinkedIn" />
          <img src={PinterestIcon} alt="Pinterest" className="pinterest-icon" />
          <img src={RedditIcon} alt="Reddit" />
          <img src={SnapchatIcon} alt="Snapchat" />
          <img src={TikTokIcon} alt="TikTok" />
          <img src={XIcon} alt="X" />
        </div>

        <div className="custom-grid-container">
          <div className="custom-left-section">
            <div className="custom-header">
              <h1>Color Wheel Speed</h1>
              <p>
              At the speed of social, the OPINARI color wheel lets anyone transmit their feelings to you in seconds with ZERO friction and in a fun way. Thumb driven answers eliminate ALL typing, driving participation rates to an entirely new level of engagement.               </p>
              <button className="cta-button-sections" onClick={goToSignUp}>Get Started</button>

            </div>



            <div className="segment-container">
    <a href="https://opinari.io/poll/121530a4b8d4" target="_blank" rel="noopener noreferrer">
      <img src={Segment} alt="Hero" />
    </a>
  </div>

            
          </div>

         
        </div>
      </div>


      

      {/* <div className="full-width-wrapper">
        <div className="custom-grid-container">
          <div className="custom-left-section">
            <div className="custom-header">
              <h1>Colorful Curiosity</h1>
              <p>
              Curiosity-driven engagement through an intuitive, self-explanatory color wheel. At the speed of social the OPINARI color wheel seamlessly integrates traditional social interaction with a new way to poll.               </p>
              <button className="cta-button-sections" onClick={toggleLightbox}>Calculate $</button>

            </div>



            

            <div className="custom-steps-grid">
              <div className="custom-card custom-small create-poll-card">
                <span className="custom-number">1</span>
                <span className="custom-title">Create Poll</span>
              </div>

              <div className="custom-card custom-small share-card">
                <span className="custom-number">2</span>
                <span className="custom-title">Share It</span>
              </div>

              <div className="custom-card custom-large">
                <span className="custom-number">3</span>
                <span className="custom-title">Gather Opinions</span>
              </div>
            </div>
          </div>

          <div className="custom-right-section">
            <div className="custom-card custom-money">
              <span className="custom-number">4</span>
              <span className="custom-title">Make Money</span>
            </div>
          </div>
        </div>
      </div> */}

      <div className="custom-shape-divider-top">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>

      <div className="full-width-wrapper-sections">
  

  <div className="gradient-section-2">
    <h2>Win-Win Always</h2>
    <p>
    We’ve partnered with Taboola™ the open webs #1 Ad platform to make sure you MAXIMIZE ad revenues. Their award winning algorithm ensures your audience is served the most relevant ads to MONETIZE every click.
    </p>
    <button className="cta-button-sections" onClick={() => navigate('/signup')}>Build Now</button>
    <img src={HandledImg} alt="Opinions" />
  </div>

  {/* <div className="gradient-section-3">
    <h2>Color-wheel flexibility</h2>
    <p>
    With over 50+ customizable answers available per poll, bringing highly relevant and lucrative ads to your followers is guaranteed.
    </p>
    <button className="cta-button-sections" onClick={() => navigate('/signup')}>Apply Now</button>
    <img src={FlexibilityImg} alt="Flexibility" />
  </div> */}
</div>
     
    </div>




    <div className="partners-container">
          <img src={Taboola} alt="YouTube" />
          <img src={Meta} alt="Facebook" />
          <img src={Tiktok} alt="Instagram" />
        </div>


    <footer className="footer">
        <div className="footer-container">
          <div className="footer-logo-section">
            <img src={Logo} alt="Logo" className="footer-logo" />
          </div>

          <div className="footer-links">
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/about">About</a></li>
              <li><a href="/contact">Contact</a></li>
              <li><a href="/FAQs">FAQs</a></li>
            </ul>
          </div>

          <div className="footer-socials">
            <a href="https://www.youtube.com/@Opinaripolls" target="_blank" rel="noopener noreferrer">
              <img src={YouTubeIcon} alt="YouTube" />
            </a>
            <a href="https://www.instagram.com/opinari.io?igsh=YzFwNjJ6bWF1end0" target="_blank" rel="noopener noreferrer">
              <img src={InstagramIcon} alt="Instagram" />
            </a>
            <a href="https://www.linkedin.com/company/104938617" target="_blank" rel="noopener noreferrer">
              <img src={LinkedInIcon} alt="LinkedIn" />
            </a>
        </div>

        </div>

        <div className="footer-bottom">
          <p>&copy; 2025 OPINARI. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
