import React, { useState, useEffect } from 'react';
import './Affiliate.css'; // Add your dark theme styles here

const Affiliate = ({ userId, username, userHash }) => {
  const [affiliateLink, setAffiliateLink] = useState('');
  const [referredUsers, setReferredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [copyStatus, setCopyStatus] = useState('Copy Link'); // State for button text

  useEffect(() => {
    // Generate the affiliate link dynamically based on user hash
    if (userHash) {
      const link = `${window.location.origin}/signup?ref=${userHash}`;
      setAffiliateLink(link);
    }
  }, [userHash]);

  useEffect(() => {
    // Fetch referred users and their stats
    const fetchReferredUsers = async () => {
      try {
        setLoading(true);
        const response = await fetch(`/api/affiliate-stats/${userId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch referred users data');
        }
        const data = await response.json();
        setReferredUsers(data.referredUsers);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReferredUsers();
  }, [userId]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(affiliateLink);
    setCopyStatus('Copied!'); // Update button text
    setTimeout(() => setCopyStatus('Copy Link'), 3000); // Reset after 3 seconds
  };

  return (
    <div className="affiliate-container">
      <h1 className="affiliate-title">Share your link!</h1>
      <p className="affiliate-description">
      When a user signs up using your link, you’ll earn 20% of all revenue generated by that user!
      </p>
      <div className="affiliate-link-container">
        <input
          type="text"
          value={affiliateLink}
          readOnly
          className="affiliate-link-input"
        />
        <button onClick={handleCopyLink} className="affiliate-copy-button">
          {copyStatus}
        </button>
      </div>
      <div className="affiliate-stats-section">
        <h2>Your Referred Users</h2>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="affiliate-error">{error}</p>
        ) : referredUsers.length > 0 ? (
          <div className="affiliate-cards">
            {referredUsers.map((user) => (
              <div key={user.userId} className="affiliate-card">
                <h3>{user.username}</h3>
                <p>Total Revenue Generated: ${user.totalRevenue.toFixed(2)}</p>
                <p>Your Commission: ${user.affiliateCommission.toFixed(2)}</p>
              </div>
            ))}
          </div>
        ) : (
          <p>You have not referred any users yet.</p>
        )}
      </div>
    </div>
  );
};

export default Affiliate;
