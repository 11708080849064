import React, { useState } from "react";
import Placeholder from "../assets/PlaceholderAdImage.jpg"; // Adjust the path if necessary
import AdType1 from "../assets/AdType1.png";
import AdType2 from "../assets/AdType2.png";
import AdType3 from "../assets/AdType3.png";

const BrandAdMockup = ({ ads, setAds, handleAdImageUpload }) => {
  const [showAdTypeSelector, setShowAdTypeSelector] = useState(false);

  const handleDescriptionChange = (index, newDescription) => {
    const updatedAds = [...ads];
    updatedAds[index].description = newDescription || "Enter ad description"; // Default to placeholder if empty
    setAds(updatedAds);
  };

  const handleLinkChange = (index, newLink) => {
    const updatedAds = [...ads];
    updatedAds[index].link = newLink || ""; // Keep blank; default is applied later
    setAds(updatedAds);
  };

  const handleBrandNameChange = (index, newBrandName) => {
    const updatedAds = [...ads];
    updatedAds[index].brandName = newBrandName || "Brand Name"; // Default value
    setAds(updatedAds);
  };

  const handleCtaTextChange = (index, newCtaText) => {
    const updatedAds = [...ads];
    updatedAds[index].ctaText = newCtaText || "Shop Now"; // Default value
    setAds(updatedAds);
  };

  const handleRemoveAd = (index) => {
    const updatedAds = ads.filter((_, i) => i !== index);
    setAds(updatedAds);
  };

  const handleAddAdWithType = (type) => {
    const newAd = {
      description: "Enter ad description",
      link: "",
      image: "", // Leave image empty
      brandName: "Brand Name",
      ctaText: "Shop Now",
    };
    setAds([...ads, newAd]);
    setShowAdTypeSelector(false); // Close the selector
  };

  return (
    <div className="ad-mockup-container">
      <div className="ads-grid">
        {ads.map((ad, index) => (
          <div key={index} className="ad-item-wrapper">
            <div className="brand-ad-container">
              <div
                className="brand-ad-image-wrapper"
                onClick={() => document.getElementById(`image-input-${index}`).click()}
              >
                <img
                  src={ad.image || Placeholder} // Use placeholder if no image
                  alt="Ad Preview"
                  className="brand-ad-image"
                />
                <input
                  type="file"
                  id={`image-input-${index}`}
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => handleAdImageUpload(e, index)}
                />
              </div>
              <div className="brand-ad-content">
                <textarea
                  className="brand-ad-description-mockup"
                  value={ad.description || "Enter ad description"} // Default value
                  onChange={(e) => handleDescriptionChange(index, e.target.value)}
                  rows="1"
                  onInput={(e) => {
                    e.target.style.height = "auto"; // Reset the height
                    e.target.style.height = `${e.target.scrollHeight}px`; // Adjust to fit content
                  }}
                  style={{
                    height: "auto", // Ensure height resets on render
                  }}
                />
                <input
                  className="brand-ad-link-mockup"
                  type="url"
                  value={ad.link}
                  onChange={(e) => handleLinkChange(index, e.target.value)}
                  placeholder="Enter link (URL)"
                />
                <div className="brand-ad-footer">
                  <div className="brand-ad-brand-container">
                    <span
                      className="brand-ad-sponsor"
                      contentEditable
                      suppressContentEditableWarning
                      onBlur={(e) => handleBrandNameChange(index, e.target.textContent)}
                    >
                      {ad.brandName || "Brand Name"}
                    </span>
                    <span className="brand-ad-divider"> | </span> {/* Divider span */}
                    <span className="brand-ad-sponsor-static">Sponsored</span>
                  </div>
                  <button
                    className="brand-ad-button"
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={(e) => handleCtaTextChange(index, e.target.textContent)}
                  >
                    {ad.ctaText || "Shop Now"}
                  </button>
                </div>
              </div>
            </div>
            <button className="remove-ad-button" onClick={() => handleRemoveAd(index)}>
              -
            </button>
            {index === ads.length - 1 && (
              <button className="add-ad-button" onClick={() => setShowAdTypeSelector(true)}>
                +
              </button>
            )}
          </div>
        ))}
      </div>

      {/* Ad Type Selector Modal */}
      {showAdTypeSelector && (
        <div className="ad-type-selector-overlay">
          <div className="ad-type-selector-container">
            <h3>Select an Ad Type</h3>
            <div className="ad-type-options">
              <button
                className="ad-type-button"
                onClick={() => handleAddAdWithType("AdType1")}
              >
                <img src={AdType1} alt="Ad Type 1" />
              </button>
              <button
                className="ad-type-button"
                onClick={() => handleAddAdWithType("AdType2")}
              >
                <img src={AdType2} alt="Ad Type 2" />
              </button>
              <button
                className="ad-type-button"
                onClick={() => handleAddAdWithType("AdType3")}
              >
                <img src={AdType3} alt="Ad Type 3" />
              </button>
            </div>
            <button
              className="close-ad-type-selector"
              onClick={() => setShowAdTypeSelector(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BrandAdMockup;
