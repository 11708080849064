import React from "react";
import "./Onboarding.css";
import { useNavigate } from "react-router-dom";

const Onboarding = ({ username, headline, subheadline }) => {
  const navigate = useNavigate();

  const suggestions = [
    "What kind of content do you want to see next?",
    "What should my next video be about?",
    "Which product should I review next?",
    "What time should I go live?",
    "What type of giveaways do you prefer?",
  ];

  const handleSuggestionClick = (question) => {
    navigate("/dashboard/main?openTab=Create Poll", { state: { question } });
  };

  return (
    <div className="Onboarding-container">
      <div className="Onboarding-card">
        <h1 className="Onboarding-welcome">{headline || `${username}, Welcome Aboard!`}</h1>
        <p className="Onboarding-subtitle">
          {subheadline ||
            "Start by creating a poll, then share it with your audience to start earning!"}
        </p>
        <button
          className="Onboarding-create-button"
          onClick={() => navigate("/dashboard/main?openTab=Create Poll")}
        >
          Create Poll +
        </button>

        <p className="Onboarding-suggestion">Not sure what to make? How about...</p>

        <div className="Onboarding-suggestions-list">
          {suggestions.map((question, index) => (
            <button
              key={index}
              className="Onboarding-suggestion-item"
              onClick={() => handleSuggestionClick(question)}
            >
              {question}
              <span className="Onboarding-arrow">→</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
