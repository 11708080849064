import React from "react";
import "./BrandAd.css";

const BrandAd = ({ description, adLink, image, brandName = "Brand Name", ctaText = "Shop Now", onClick }) => {
  const handleClick = () => {
    if (!adLink) {
      return; // Prevent click action if the link is invalid
    }
    onClick && onClick(); // Trigger any additional click actions
    window.open(adLink, "_blank"); // Open the ad link in a new tab
  };

  return (
    <div className="brand-ad-container" onClick={handleClick}>
      <div className="brand-ad-image-wrapper">
        {image && (
          <img src={image} alt="Ad Image" className="brand-ad-image" />
        )}
      </div>
      <div className="brand-ad-content">
        <p className="brand-ad-description">{description}</p>
        <div className="brand-ad-footer">
          <span className="brand-ad-sponsor">{brandName} | Sponsored</span>
          <button className="brand-ad-button">{ctaText}</button>
        </div>
      </div>
    </div>
  );
};

export default BrandAd;
