import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "./Revenue.css";

const Revenue = ({ userId }) => {
  const [revenueData, setRevenueData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOption, setSortOption] = useState("highest");
  const [dateRange, setDateRange] = useState("all");
  const navigate = useNavigate();

  // Use a key that is specific to the user, so each user has its own data in localStorage.
  const localStorageKey = `revenueData_${userId}`;

  /**
   * Fetch data from your backend
   */
  const fetchUserRevenueData = async () => {
    console.log("Fetching user revenue data...");
    setLoading(true);
    try {
      const response = await fetch(`/fetch-user-revenue?userId=${userId}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed Response Text:", errorText);
        throw new Error(`Request failed with status ${response.status}`);
      }

      const { revenueData } = await response.json();
      console.log("Fetched User Revenue Data:", revenueData);

      // Transform data (if needed)
      const transformedData = revenueData.map((poll) => ({
        ...poll,
        data: [
          {
            date: new Date().toISOString().split("T")[0], // Today's date
            impressions: poll.visits || 0,
            clicks: 0, // Placeholder value
            revenue: poll.revenue || 0,
          },
        ],
      }));

      console.log("Transformed Data:", transformedData);

      // Store in component state
      setRevenueData(transformedData);
      setFilteredData(transformedData);

      // Also store in localStorage to avoid repeated fetches
      localStorage.setItem(localStorageKey, JSON.stringify(transformedData));
    } catch (error) {
      console.error("Error fetching user revenue data:", error.message);
    } finally {
      setLoading(false);
      console.log("Request to /fetch-user-revenue completed.");
    }
  };

  /**
   * On mount:
   * 1. Try to load data from localStorage.
   * 2. If data is found, use that.
   * 3. If not, fetch from server.
   */
  useEffect(() => {
    const savedData = localStorage.getItem(localStorageKey);

    if (savedData) {
      console.log("Loading revenue data from localStorage...");
      const parsedData = JSON.parse(savedData);
      setRevenueData(parsedData);
      setFilteredData(parsedData);
      setLoading(false);
    } else {
      // localStorage is empty for this user, do an initial fetch
      fetchUserRevenueData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  /**
   * Handle manual refresh
   * - Forces a new fetch to update local storage data
   */
  const handleRefresh = async () => {
    await fetchUserRevenueData();
  };

  // Re-run filtering and sorting whenever data, sortOption, or dateRange changes.
  useEffect(() => {
    if (!revenueData.length) return;

    let filtered = [...revenueData];
    filtered = filtered.map((item) => ({
      ...item,
      data: groupDataByRange(item.data || [], dateRange),
    }));

    // Sorting logic
    filtered.sort((a, b) => {
      const totalA = a.data.reduce(
        (sum, entry) => sum + (parseFloat(entry.revenue) || 0),
        0
      );
      const totalB = b.data.reduce(
        (sum, entry) => sum + (parseFloat(entry.revenue) || 0),
        0
      );
      return sortOption === "highest" ? totalB - totalA : totalA - totalB;
    });

    setFilteredData(filtered);
  }, [sortOption, dateRange, revenueData]);

  // Helper: Group Data By Range
  const groupDataByRange = (data, range) => {
    const now = new Date();
    if (!data.length) return [];

    if (range === "all") {
      const grouped = data.reduce((acc, entry) => {
        const month = new Date(entry.date).toLocaleString("default", {
          month: "short",
        });
        if (!acc[month]) acc[month] = { revenue: 0 };
        acc[month].revenue += parseFloat(entry.revenue) || 0;
        return acc;
      }, {});

      return Object.entries(grouped).map(([key, value]) => ({
        date: key,
        ...value,
      }));
    }
    // If you add more date range logic (like last-month, last-week), handle it here.

    return data; // If no special grouping, just return as-is
  };

  const calculateTotalEarnings = () => {
    return filteredData.reduce((total, poll) => {
      return (
        total +
        (poll.data || []).reduce(
          (sum, entry) => sum + (parseFloat(entry.revenue) || 0),
          0
        )
      );
    }, 0);
  };

  return (
    <div className="revenue">
      <div className="filters">
      
        <select
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
        >
          <option value="highest">Highest Earning</option>
          <option value="lowest">Lowest Earning</option>
        </select>
        <div className="refresh-button-container">
        <button onClick={handleRefresh} disabled={loading}>
          {loading ? "Refreshing..." : "Refresh"}
        </button>
      </div>
        {/* <select
          value={dateRange}
          onChange={(e) => setDateRange(e.target.value)}
        >
          <option value="all">All Time</option>
          <option value="last-month">Last Month</option>
          <option value="last-week">Last Week</option>
        </select> */}
      </div>

      

      <div className="total-earnings">
        <h1>
          Estimated Gross Earnings:{" "}
          <span className="earnings-amount">
            ${calculateTotalEarnings().toFixed(2)}
          </span>
        </h1>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="card-grid">
          {filteredData.map((poll, index) => {
            const totalRevenue = poll.data.reduce(
              (sum, entry) => sum + (parseFloat(entry.revenue) || 0),
              0
            );

            return (
              <div
                key={index}
                className={`revenue-card ${
                  totalRevenue === 0 ? "card-zero-revenue" : ""
                }`}
              >
                <div
                  className="redirect-icon-container"
                  onClick={() => navigate(`/poll/${poll.hash}`)}
                >
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    className="redirect-icon"
                  />
                </div>

                <h2 className="poll-rev-question">“{poll.question}”</h2>

                {/* Big Revenue Number */}
                <div className="big-revenue">
                  <span
                    className={`big-revenue-value ${
                      totalRevenue === 0 ? "grey-revenue" : ""
                    }`}
                  >
                    ${totalRevenue.toFixed(2)}
                  </span>
                </div>

                {/* Additional Stats */}
                <div className="stats-container">
                  <p>
                    <strong>Impressions:</strong>{" "}
                    {poll.visits?.toLocaleString() || 0}
                  </p>
                  <p>
                    <strong>Page Views:</strong>{" "}
                    {poll.page_views?.toLocaleString() || 0}
                  </p>
                  <p>
                    <strong>Ad RPM:</strong>{" "}
                    ${poll.ad_rpm?.toFixed(2) || "0.00"}
                  </p>
                  <p>
                    <strong>Bounce Rate:</strong>{" "}
                    {poll.bounce_rate != null
                      ? `${poll.bounce_rate.toFixed(2)}%`
                      : "N/A"}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Revenue;
