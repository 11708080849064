import React, { useState, useEffect, useRef, useCallback } from 'react';
import Poll from './Poll';
import PollSuccessModal from './PollSuccessModal';
import Onboarding from './Onboarding'; // Import the Onboarding component
import { useNavigate } from 'react-router-dom';

const MyPolls = ({
  userId,
  username,
  userEmail,
  isLoggedIn,
  isProfileView,
  pollSuccessData,
  onCloseSuccessModal,
  onCreateReportClick,
}) => {
  const [polls, setPolls] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState('createdAtDesc'); // Default sorting
  const [page, setPage] = useState(0); // Page number
  const [hasMore, setHasMore] = useState(true); // Flag to indicate if more data can be loaded
  const loaderRef = useRef(null);
  const [observerEnabled, setObserverEnabled] = useState(false); // Controls IntersectionObserver
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    if (pollSuccessData) {
      setShowSuccessModal(true);
    }
  }, [pollSuccessData]);

  useEffect(() => {
    setPolls([]);
    setPage(0);
    setHasMore(true);
    setObserverEnabled(false); // Disable observer temporarily
    fetchPolls(0, true); // Fetch the first page explicitly
  }, [sort, userId]);

  const fetchPolls = async (pageNumber, isFirstPage = false) => {
    setIsLoading(true);
    try {
      const limit = 10;
      const skip = pageNumber * limit;
      const url = `/polls?creator=${userId}&pollType=Creator&sort=${sort}&limit=${limit}&skip=${skip}`;

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch polls');
      }

      const data = await response.json();
      const newPolls = data.polls || [];

      if (newPolls.length < limit) {
        setHasMore(false); // No more data to load
      }

      setPolls((prev) => (isFirstPage ? newPolls : [...prev, ...newPolls])); // Replace or append polls

      if (isFirstPage) {
        setObserverEnabled(true); // Enable observer after the first page loads
      }
    } catch (err) {
      console.error('Error fetching user polls:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore && !isLoading && observerEnabled) {
        setPage((prevPage) => prevPage + 1);
      }
    },
    [hasMore, isLoading, observerEnabled]
  );

  useEffect(() => {
    if (page >= 0) {
      fetchPolls(page, page === 0); // Pass isFirstPage as true only for the first page
    }
  }, [page]);

  useEffect(() => {
    if (observerEnabled) {
      const observer = new IntersectionObserver(handleObserver, {
        root: null,
        rootMargin: '200px',
        threshold: 0.1,
      });

      if (loaderRef.current) {
        observer.observe(loaderRef.current);
      }

      return () => {
        if (loaderRef.current) {
          observer.unobserve(loaderRef.current);
        }
      };
    }
  }, [handleObserver, observerEnabled, hasMore]);

  const closeModal = () => {
    setShowSuccessModal(false);
    onCloseSuccessModal();
  };

  return (
    <div>
      {showSuccessModal && pollSuccessData && (
        <PollSuccessModal
          pollLink={pollSuccessData.pollLink}
          isTemporary={pollSuccessData.isTemporary}
          onClose={closeModal}
        />
      )}

      {/* Conditionally render the sorting dropdown */}
      {polls.length > 0 && (
        <div className="Sorting-dropdown filters" style={{ marginBottom: '1rem' }}>
          <select
            id="sort"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
            className="filters-select"
          >
            <option value="createdAtDesc">Most Recent</option>
            <option value="votesDesc">Most Votes</option>
            <option value="votesAsc">Least Votes</option>
          </select>
        </div>
      )}

      <div className="Root-grid-container">
        {polls.map((poll) => (
          <div key={poll._id} className="Poll-item" style={{ position: 'relative' }}>
            {!poll.approved && (
              <div
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgb(15 15 15 / 55%)',
                  zIndex: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontSize: '24px',
                  borderRadius: '10px',
                }}
              >
                Poll Under Review
              </div>
            )}
            <Poll
              pollId={poll._id}
              question={poll.question}
              labels={poll.labels}
              logo={poll.logo || '/logo-poll.png'}
              logoFill={poll.logoFill}
              votes={poll.votes}
              hash={poll.hash}
              creatorUsername={poll.creatorUsername}
              creatorId={poll.creator}
              isLoggedIn={isLoggedIn}
              userId={userId}
              username={username}
              onDelete={() => setPolls((prev) => prev.filter((p) => p._id !== poll._id))}
              isProfileView={true}
              voteAuthenticationMethod={poll.voteAuthenticationMethod}
              confidenceLabels={poll.confidenceLabels}
              imageAction={poll.imageAction}
              roundedCorners={poll.roundedCorners}
              startDate={poll.startDate}
              endDate={poll.endDate}
              scheduledPoll={poll.scheduledPoll}
              userEmail={userEmail}
              totalVotes={poll.totalVotes}
              isTemporary={poll.isTemporary}
              isLegacyPoll={poll.isLegacyPoll}
              approved={poll.approved}
              onCreateReportClick={() => onCreateReportClick(poll._id)}
            />
          </div>
        ))}
      </div>

      {isLoading && <p style={{ textAlign: 'center' }}>Loading polls...</p>}

      {!isLoading && !hasMore && polls.length === 0 && (
        <div style={{ display: 'flex', justifyContent: 'center', height: '80vh' }}>
          <Onboarding username={username} />
        </div>
      )}

      <div ref={loaderRef} style={{ height: '1px', marginTop: '1rem' }} />
    </div>
  );
};

export default MyPolls;
