import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import logger from './logger';

const QRCodeGeneratorCreate = ({ pollLink }) => {
  const downloadQRCode = () => {
    const canvas = document.getElementById('qr-code-canvas');
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'poll-qr-code.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div style={{ display: 'inline-grid' , marginBottom: '10px' }}>
      <QRCodeCanvas
        id="qr-code-canvas"
        value={pollLink}
        size={512}
        bgColor="#ffffff"
        fgColor="#202021"
        level="L"
        includeMargin={false}
        imageSettings={{
          src: require('./qr-icon.png'), // Ensure this path matches your project structure
          x: undefined,
          y: undefined,
          height: 100,
          width: 100,
          excavate: true,
        }}
        style={{
          height: '170px',
          width: '170px',
          borderRadius: '15px',
          padding: '15px',
          boxSizing: 'content-box',
          background: 'white',
          marginBottom: '10px',
        }}
      />
      <button onClick={downloadQRCode} className="tools-button">
        Download QR Code
      </button>
    </div>
  );
};

export default QRCodeGeneratorCreate;
